import * as React from "react";
import * as ReactDOM from "react-dom";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import { ParticipantPage } from "./pages/ParticipantPage";
import { AdminPage } from "./pages/AdminPage";
import "@fontsource/vt323";
import "./index.css";

function App(): JSX.Element {
  function render(): JSX.Element {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/">
            <ParticipantPage />
          </Route>
          <Route exact path="/admin/:key">
            <AdminPage />
          </Route>
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      </HashRouter>
    );
  }

  return render();
}

ReactDOM.render(<App />, document.getElementById("app"));
