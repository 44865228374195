import { Error } from "./models";

export function post<T>(path: string, data: any): Promise<T> {
  return request(path, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
}

export function put<T>(path: string, data: any): Promise<T> {
  return request(path, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
}

export function patch<T>(path: string, data: any): Promise<T> {
  return request(path, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
}

export function get<T>(path: string): Promise<T> {
  return request(path, { method: "GET" });
}

export function del<T>(path: string, data: any): Promise<T> {
  return request(path, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
}

export function request<T>(path: string, init: RequestInit): Promise<T> {
  return fetch(path, init)
    .catch((error: any) => {
      throw networkError(error);
    })
    .then((response: Response) => {
      if (response.status === 204) {
        return {} as T; // FIXME
      }
      if (!response.ok) {
        return response
          .json()
          .catch(() => {
            throw unexpectedError(response.status);
          })
          .then((json: Error) => {
            throw json as Error;
          });
      }
      return response
        .json()
        .catch((error: any) => {
          throw jsonError(error);
        })
        .then((json: T) => {
          return json as T;
        });
    });
}

function networkError(error: any): Error {
  return {
    code: 600,
    reason: "Network error",
    details: error.toString(),
  };
}

function jsonError(error: any): Error {
  return {
    code: 601,
    reason: "Invalid JSON",
    details: error.toString(),
  };
}

function unexpectedError(code: number): Error {
  return {
    code: code,
    reason: "Unexpected error",
    details: null,
  };
}
