import * as React from "react";
import "./SideBySide.css";

type Props = {
  left: JSX.Element;
  right: JSX.Element;
};

export function SideBySide(props: Props): JSX.Element {
  return (
    <div className="side-by-side">
      {props.left}
      {props.right}
    </div>
  );
}
