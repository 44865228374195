import * as React from "react";
import { SideBySide } from "../components/SideBySide";
import { TaskView } from "../components/TaskView";
import { useState } from "react";
import { State, Task } from "../api/models";
import { useInterval } from "../hooks/useInterval";
import { getState } from "../api/stateApi";
import { getTask } from "../api/taskApi";
import { StateControls } from "../components/StateControls";

export function AdminPage(): JSX.Element {
  const [state, setState] = useState<State>();
  const [task, setTask] = useState<Task>();

  useInterval(refreshState, 500);

  function refreshState() {
    getState()
      .then((newState) => {
        setState(newState);
        if (newState.task != state?.task) {
          refreshTask(newState.task);
        }
      })
      .catch((error) => console.log("State error", error));
  }

  function refreshTask(id: number) {
    getTask(id)
      .then((task) => setTask(task))
      .catch((error) => console.log("Task error", error));
  }

  if (!state || !task) {
    return <div />;
  }

  return (
    <SideBySide
      left={<StateControls state={state} />}
      right={<TaskView task={task} />}
    />
  );
}
