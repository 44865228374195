import * as React from "react";
import "./Fullscreen.css";

type Props = {
  children: JSX.Element;
};

export function Fullscreen(props: Props): JSX.Element {
  return <div className="fullscreen">{props.children}</div>;
}
