import { get, patch } from "./request";
import { State, UpdateState } from "./models";

export function getState(): Promise<State> {
  return get("/api/v1/state");
}

export function updateState(key: String, update: UpdateState): Promise<State> {
  return patch("/api/v1/state?key=" + key, update);
}
