import * as React from "react";
import { useEffect, useRef } from "react";
import { Task } from "../api/models";
import "./TaskView.css";

type Props = {
  task: Task;
};

export function TaskView(props: Props): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const elements: HTMLElement[] = [];
    if (props.task.imageUrl) {
      const el = document.createElement("div");
      el.className = "image";
      el.style.backgroundImage = "url('" + props.task.imageUrl + "')";
      elements.push(el);
    }
    if (props.task.audioUrl) {
      const el = document.createElement("audio");
      el.className = "audio";
      el.src = props.task.audioUrl;
      el.autoplay = true;
      el.controls = true;
      elements.push(el);
    }
    if (props.task.videoUrl) {
      const el = document.createElement("video");
      el.className = "video";
      el.src = props.task.videoUrl;
      el.autoplay = true;
      el.controls = true;
      elements.push(el);
    }
    elements.forEach((el) => ref.current!.appendChild(el));
    return () => {
      setTimeout(() => {
        elements.forEach((el) => ref.current!.removeChild(el));
      }, 1000);
    };
  }, [JSON.stringify(props.task)]);

  return <div className="task-view" ref={ref} />;
}
