import * as React from "react";
import { useState } from "react";
import { State, Task } from "../api/models";
import { Score } from "../components/Score";
import { TaskView } from "../components/TaskView";
import { getState } from "../api/stateApi";
import { getTask } from "../api/taskApi";
import { useInterval } from "../hooks/useInterval";
import { Fullscreen } from "../components/Fullscreen";

export function ParticipantPage(): JSX.Element {
  const [state, setState] = useState<State>();
  const [task, setTask] = useState<Task>();

  useInterval(refreshState, 500);

  function refreshState() {
    getState()
      .then((newState) => {
        setState(newState);
        if (newState.task != state?.task) {
          refreshTask(newState.task);
        }
      })
      .catch((error) => console.log("State error", error));
  }

  function refreshTask(id: number) {
    getTask(id)
      .then((task) => setTask(task))
      .catch((error) => console.log("Task error", error));
  }

  if (!state || !task) {
    return <div />;
  }

  return (
    <>
      <Score score={state.score} />
      <Fullscreen>
        <TaskView task={task} />
      </Fullscreen>
    </>
  );
}
