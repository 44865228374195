import * as React from "react";
import "./Score.css";

// @ts-ignore
import star from "url:../images/star.gif";

type Props = {
  score: number;
};

export function Score(props: Props): JSX.Element {
  return (
    <div className="score">
      <span className="text">Score:</span>
      <img className="star" src={star} alt="" />
      <span className="text">{props.score}</span>
    </div>
  );
}
