import * as React from "react";
import { State } from "../api/models";
import "./StateControls.css";
import { updateState } from "../api/stateApi";
import { useParams } from "react-router-dom";
import { useState } from "react";

type Params = {
  key: string;
};

type Props = {
  state: State;
};

export function StateControls(props: Props): JSX.Element {
  const params = useParams<Params>();
  const [task, setTask] = useState("0");

  function updateScore(delta: number) {
    updateState(params.key, {
      task: null,
      score: props.state.score + delta,
    });
  }

  function updateTask(delta: number) {
    updateState(params.key, {
      task: props.state.task + delta,
      score: null,
    });
  }

  function gotoTask(task: number) {
    updateState(params.key, {
      task: task,
      score: null,
    });
  }

  return (
    <div className="state-controls">
      <h1>Score: {props.state.score}</h1>
      <div>
        <button onClick={() => updateScore(-3)}>-3</button>
        <button onClick={() => updateScore(+3)}>+3</button>
      </div>
      <div>
        <button onClick={() => updateScore(-2)}>-2</button>
        <button onClick={() => updateScore(+2)}>+2</button>
      </div>
      <div>
        <button onClick={() => updateScore(-1)}>-1</button>
        <button onClick={() => updateScore(+1)}>+1</button>
      </div>
      <div>
        <button onClick={() => updateScore(-0.5)}>-0.5</button>
        <button onClick={() => updateScore(+0.5)}>+0.5</button>
      </div>
      <h1>Task: {props.state.task}</h1>
      <div>
        <button onClick={() => updateTask(-1)}>Prev</button>
        <button onClick={() => updateTask(+1)}>Next</button>
      </div>
      <div>
        <input
          type="text"
          value={task}
          onChange={(e) => setTask(e.target.value)}
        />
        <button onClick={() => gotoTask(parseInt(task))}>Goto</button>
      </div>
    </div>
  );
}
